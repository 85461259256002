import LoginForm from '../form/LoginForm';
import Link from 'next/link'
import Image from 'next/image'
import logo from '/public/logotipo6.png'


const LoginComponent = () => {
  return (
    <div className = "flex flex-col items-center  w-96 h-[48rem] p-6 bg-white rounded-3xl text-center">
      <Image className='mb-14' src={logo} alt="logotipo da Leapy" width='145' height='138' />
      <div className="flex flex-col gap-12 w-full">
        <div className="flex flex-col gap-3 text-leapy-black">
          <h2 className="font-krona font-normal text-xl ">Boas vindas à Leapy! </h2>
          <p className="font-montserrat font-medium text-sm opacity-60">Faça seu login e acesse à plataforma</p>
        </div>
        <LoginForm/>
      </div>
      <div className="flex flex-col justify-center mt-8 h-10 gap-4">
        <Link className='font-montserrat text-sm font-semibold text-leapy-black hover:text-leapy-blue' href='/auth/forget_password'>Esqueci minha senha</Link>
        <span className='font-montserrat text-sm font-light text-leapy-black'>Para criar uma conta <Link className='font-montserrat text-sm font-semibold text-leapy-blue hover:text-leapy-green' href='/auth/signup'>clique aqui</Link></span>
      </div>
    </div>
  )
};
export default LoginComponent;
