import LoginComponent from "../../components/auth/login/LoginComponent";
import Link from 'next/link'
import Image from 'next/image'
import leaperPicture from '/public/leaperComputer.png'
import grafismoPicture from '/public/Grafismo03_6.png'
import grafismoDoisPicture from '/public/Grafismo02_5.png'


const Login = () => {
  return (
    <div className="h-full relative overflow-hidden bg-leapy-blue">
        {/* <Image className='absolute rotate-90 ' src={grafismoDoisPicture} alt='/' /> */}
        <Image className='absolute bottom-0 left-2/4' src={grafismoPicture} alt='/'width='800' height='800'/>
        <Image className='absolute bottom-0 left-2/4' src={leaperPicture} alt='/' width='1062' height='857'/>
        <div className="absolute h-full w-1/2 flex flex-col justify-center items-center">
          <LoginComponent />
        </div>
    </div>
  );
};
export default Login;
