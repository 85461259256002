import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Input from './Input'
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';

const LoginForm = () => {
  // Set up states and hooks
  const [apiResponse,setApiResponse]= useState({})
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const [isSafeToReset, setIsSafeToReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, isAuthenticated } = useContext(AuthContext);

  // Method to handleLogin event
  const handleLogin = async (data, event) => {
    // Set states
    //setIsSafeToReset(false)
    setIsLoading(true)

    // Stop the form from submitting and refreshing the page.
    event.preventDefault()

    // Call signIn function from our AuthContext
    const response = await signIn(data) // response JSON format

    // Get message from api and save like an state
    setApiResponse(response)

    // Update states
    // setIsSafeToReset(true);
    setIsLoading(false)
    reset()
  }

  // Validations
  const emailValidation = {
    required: 'Campo obrigatório',
    pattern: {
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
      message: 'E-mail inválido'
    }
  }

  const passwordValidation = {
    required: 'Campo obrigatório',
    minLength: {
      value: 6,
      message: 'Mínimo 6 caracteres'
    },
    // pattern: {
    //   value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!_%*#?&])[A-Za-z\d@$!_%*#?&]{6,}$/,
    //   message: 'Minimo uma letra, um numero e um caracter especial'
    // }
  }

  const confirmPasswordValidation = {
    validate:  value => {
      if(watch('password') != value) {
        return 'As senhas não são iguais'
      }
    }
  }

  return (
    <form class= "flex flex-col text-left gap-3" onSubmit={handleSubmit(handleLogin)}>

      {/*  Input email element */}
      <Input
      type='text'
        text='E-mail'
        name='email'
        placeholder='seuemail@exemplo.com'
        validation = {emailValidation}
        register = {register}
        error = {errors.email}
      />

      {/* Input password element */}
      <Input
        type='password'
        text='Senha'
        name='password'
        placeholder='Digite sua senha'
        validation = {passwordValidation}
        register = {register}
        error = {errors.password}
      />

      {apiResponse?.success === false && <span className='font-montserrat text-xs font-light text-leapy-red mt-1 ml-1'>{apiResponse?.errors[0]}</span> }
      {apiResponse?.success === 'success' && <span className='font-montserrat text-xs font-semibold text-leapy-green-dark mt-1 ml-1'>Conta criada com sucesso. Acesse seu email para validá-la! </span> }

      {/* Submit button */}
      <button
        className= 'bg-leapy-blue hover:bg-leapy-green font-montserrat text-sm font-semibold text-white rounded-lg h-10 mt-12 disabled:bg-leapy-white disabled:text-leapy-black-20'
        // className = 'font-montserrat text-sm font-semibold bg-leapy-white text-leapy-black-20 border border-leapy-white'
        type="submit"
        disabled={isLoading}>
        { isLoading ? 'Entrando...': 'Entrar'}
      </button>
    </form>
  );
};
export default LoginForm;
