import React, { useState, useEffect } from 'react';

const Input = ({type, text, name, placeholder,validation, value, width, register, error}) => {
  return (
    <div className="flex flex-col">
        <div className="flex justify-center relative max-w-max top-2.5 left-1.5 bg-white">
          <label className="font-montserrat text-xs font-bold" htmlFor="email">{text}</label>
        </div>
        {( type === 'password')
          ? <input {...register(name,validation)}
              className="font-montserrat text-2xl font-semibold border border-leapy-black-40 radius-4 rounded-lg h-10 pl-4 placeholder:font-montserrat placeholder:text-sm placeholder:font-medium autofill:bg-yellow-200 "
              type={type}
              placeholder = {placeholder}
            />
          : <input {...register(name,validation)}
              className="font-montserrat text-sm font-light border border-leapy-black-40 radius-4 rounded-lg h-9 pl-4 placeholder:font-montserrat placeholder:text-sm placeholder:font-medium autofill:bg-yellow-200"
              type={type}
              placeholder = {placeholder}
            />
        }
        {error && <span className='font-montserrat text-xs font-light text-leapy-red mt-1 ml-1' >{error.message}</span>}
      </div>
  );
};

export default Input;
